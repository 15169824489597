import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_AUTH_PREFIX;
const RECO_API_PREFIX = process.env.REACT_APP_RECO_API_URL;
const headers = {
  headers: { 'Content-Type': 'multipart/form-data' },
};
export const register = async (payload) => {
  return await axios
    .post(`${RECO_API_PREFIX}${API_PREFIX}auth/register`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const login = async (payload) => {
  return await axios
    .post(`${RECO_API_PREFIX}${API_PREFIX}auth/login`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const forgotPassword = async (payload) => {
  return await axios
    .post(`${RECO_API_PREFIX}${API_PREFIX}auth/forgot-password`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const logout = async () => {
  return await axios
    .post(`${RECO_API_PREFIX}${API_PREFIX}auth/logout`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const verify = async (token) => {
  return await axios
    .get(`${RECO_API_PREFIX}${API_PREFIX}auth/verify/${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const resetPassword = async (token, payload) => {
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axios
    .put(`${RECO_API_PREFIX}${API_PREFIX}auth/reset-password`, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const verifyToken = async (token) => {
  return await axios
    .get(`${RECO_API_PREFIX}${API_PREFIX}auth/salelease/${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const updateProfile = async (payload) => {
  return await axios
    .put(`${RECO_API_PREFIX}/web${API_PREFIX}users/update`, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
