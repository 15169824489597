import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import CustomModal from '../../components/CustomModal/CustomModal';
import { FormField, FormFieldPassword } from '../../components/Form/FormField';
import { loginSchema } from './AuthSchema';
import { addAuthData } from '../../store/slices/authUser/authUserSlice';
import * as AuthAPI from '../../api/AuthAPI';
import { showLoginModal } from '../../store/slices/authUser/loginModalSlice';
import { ROLES } from '../../common/constants';
const Login = ({
  loginModal,
  resetLoginModal,
  setSignupModal,
  setForgotPasswordModal,
}) => {
  const dispatch = useDispatch();
  const { show, title, saveBtnText } = loginModal;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['rememberMe']);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors: loginError },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
  });
  const formSubmit = async (formData) => {
    if (formData.rememberMe) {
      setCookie('rememberMe', formData.rememberMe, { path: '/' });
      setCookie('email', formData.email, { path: '/' });
      setCookie('token', btoa(formData.password), { path: '/' });
    } else {
      removeCookie('rememberMe');
      removeCookie('email');
      removeCookie('token');
    }
    setIsLoading(true);
    try {
      const { data } = await AuthAPI.login(formData);
      if (data && data?.user) {
        const {
          token,
          roles,
          is_trial_plan,
          is_active_plan,
          plan_end_date,
          currentPlan,
        } = data;
        const {
          id,
          gender,
          first_name,
          last_name,
          company_name = '',
          profile_image,
          phone,
          email,
          address = '',
          zipcode = '',
          city = '',
          province = '',
          country = '',
          latitude = '',
          longitude = '',
        } = data?.user;
        const currentRole =
          roles.find((role) => role.isCurrentRole === true) || roles[0] || {};
        const role = currentRole ? currentRole.role_slug : undefined;
        const restrictRoles = [ROLES.VENDOR, ROLES.TENANT];
        if (restrictRoles.includes(role)) {
          throw new Error('Email or Password is invalid');
        }
        dispatch(
          addAuthData({
            id: id,
            gender: gender,
            first_name: first_name || '',
            last_name: last_name || '',
            company_name: company_name || '',
            profile_image: profile_image || '',
            email: email || '',
            phone: phone || '',
            auth: true,
            device: 'web',
            role: currentRole?.role_slug || '',
            token: token,
            is_active_plan,
            is_trial_plan,
            plan_end_date,
            currentPlan,
            address,
            zipcode,
            city,
            province,
            country,
            latitude,
            longitude,
          }),
        );
        resetLoginModal(false);
      }
    } catch (error) {
      setIsError(true);
      const message = error?.message?.email?.message
        ? error.message.email.message
        : error.message;
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };
  const showSignupModal = () => {
    // setSignupModal((prevState) => ({
    //     ...prevState,
    //     show: true,
    // }));
    // resetLoginModal();
    dispatch(
      showLoginModal({
        login: false,
        signup: true,
      }),
    );
  };
  const showForgotPasswordModal = (e) => {
    e.preventDefault();
    setForgotPasswordModal((prevState) => ({
      ...prevState,
      show: true,
    }));
    resetLoginModal();
  };
  useEffect(() => {
    if (cookies.rememberMe) {
      setValue('email', cookies.email);
      setValue('password', atob(cookies.token));
      setValue('rememberMe', cookies.rememberMe);
    }
  }, []);
  return (
    <CustomModal
      show={show}
      isLoading={isLoading}
      onHide={() => resetLoginModal(false)}
      modalHeading={title}
      saveBtnText={saveBtnText}
      onSaveCallback={handleSubmit(formSubmit)}
      hasActions={false}
    >
      <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
        {isError && (
          <Alert variant="danger" className="p-2">
            {errorMessage}
          </Alert>
        )}
        <Row className="row-cards mb-3">
          <FormField
            label="Email"
            name="email"
            error={loginError?.email}
            register={register}
            size={12}
            required
            autoFocus
          />
          <FormFieldPassword
            label="Password"
            name="password"
            error={loginError?.password}
            register={register}
            size={12}
            required
          />
          <Form.Group
            as={Col}
            md="auto"
            className="mb-3"
            controlId="formForgotPasswordCheckbox"
          >
            <Form.Check
              type="checkbox"
              label="Remember Me"
              {...register('rememberMe')}
              className="mb-0"
            />
          </Form.Group>
          <Col className="text-end">
            <Link
              to={''}
              className="fw-semibold"
              onClick={showForgotPasswordModal}
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Button variant="primary" type="submit">
              {saveBtnText} <IconArrowNarrowRight className="icon ms-1 me-0 " />
            </Button>
          </Col>
          <Col className="text-end">
            Doesn’t have an account?{' '}
            <Link to={''} className="fw-bold" onClick={showSignupModal}>
              Sign Up
            </Link>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default Login;
