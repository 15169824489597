import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CustomModal from '../../components/CustomModal/CustomModal';
import {
  FormField,
  FormFieldAddress,
  FormFieldCheck,
  FormFieldPassword,
} from '../../components/Form/FormField';
import { IconArrowNarrowRight, IconCircleCheck } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { signupSchema } from './AuthSchema';
import * as AuthAPI from '../../api/AuthAPI';
import { handleApiError } from '../../common/errorHandler';
import { showLoginModal } from '../../store/slices/authUser/loginModalSlice';
import { useDispatch } from 'react-redux';
const Signup = ({ signupModal, resetSignupModal }) => {
  const dispatch = useDispatch();
  const { show, title, saveBtnText } = signupModal;
  const [isLoading, setIsLoading] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const {
    register,
    control,
    setValue,
    setError,
    trigger,
    handleSubmit,
    formState: { errors: signupError },
  } = useForm({
    mode: 'onChange',
    defaultValues: { role: 'guest', address: '', rentals: true },
    resolver: yupResolver(signupSchema),
  });
  const formSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const { data } = await AuthAPI.register(formData);
      if (data) {
        setIsSignup(true);
      }
    } catch (error) {
      handleApiError(error, setError);
    } finally {
      setIsLoading(false);
    }
  };
  const handleShowLoginModal = () => {
    dispatch(
      showLoginModal({
        login: true,
        signup: false,
      }),
    );
    // resetSignupModal();
  };
  return (
    <CustomModal
      show={show}
      isLoading={isLoading}
      onHide={() => resetSignupModal(false)}
      modalHeading={title}
      saveBtnText={saveBtnText}
      onSaveCallback={handleSubmit(formSubmit)}
      hasActions={false}
    >
      <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
        {isSignup && (
          <div className="text-center w-100 fade-in">
            <IconCircleCheck className="icon icon-lg text-green mb-2" />
            <h1 className="mt-2  text-success">Sign Up successfully!</h1>
            <p className="text-info fs-3">
              An email has been sent to you. <br />
              Please check your email and verify.
            </p>
            <Link
              to=""
              onClick={handleShowLoginModal}
              className="fw-bold w-100"
            >
              {' '}
              Login
            </Link>
          </div>
        )}
        {!isSignup && (
          <Fragment>
            <Row className="row-cards mb-3">
              <FormField
                label="First Name"
                name="first_name"
                error={signupError?.first_name}
                register={register}
                required
                autoFocus
              />
              <FormField
                label="Last Name"
                name="last_name"
                error={signupError?.last_name}
                register={register}
                required
              />
              <FormFieldAddress
                label="Address"
                name="address"
                error={signupError.address}
                register={register}
                control={control}
                setValue={setValue}
                size="12"
                trigger={trigger}
                hasDetails={true}
                required
              />
              <FormField
                label="Email"
                name="email"
                error={signupError?.email}
                register={register}
                required
              />
              <FormField
                label="Phone"
                name="phone"
                type="number"
                error={signupError?.phone}
                register={register}
                required
              />
              <FormFieldPassword
                label="Password"
                name="password"
                error={signupError?.password}
                register={register}
                required
              />
              <FormFieldPassword
                label="Confirm Password"
                name="confirm_password"
                type="password"
                error={signupError?.confirm_password}
                register={register}
                placeholder="Enter confirm password"
                required
              />
            </Row>
            <Row className="align-items-center">
              <Col>
                <Button variant="primary" type="submit">
                  {saveBtnText}{' '}
                  <IconArrowNarrowRight className="icon ms-1 me-0 " />
                </Button>
              </Col>
              <Col className="text-end">
                Already have an account?{' '}
                <Link
                  to={''}
                  className="fw-bold"
                  onClick={handleShowLoginModal}
                >
                  Login
                </Link>
              </Col>
            </Row>
          </Fragment>
        )}
      </Form>
    </CustomModal>
  );
};

export default Signup;
