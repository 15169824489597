import { IconArrowLeft, IconMenu2 } from '@tabler/icons-react';
import React, { useState } from 'react';
import { Button, Nav, Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { TEXT_CONSTANT } from '../../common/constants';
import { useSelector } from 'react-redux';
import CountryDropdown from '../Form/CountryDropdown';

const MobileMenu = ({
  handleShowLogin,
  redirectTo,
  activeLink = '',
  handleNavClick,
}) => {
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleMobileNavClick = (sectionId) => {
    handleNavClick(sectionId);
    handleClose();
  };
  return (
    <>
      <IconMenu2 onClick={handleShow} />

      <Offcanvas show={show} onHide={handleClose} className="w-75">
        <Offcanvas.Body className="p-3">
          <div className="py-2 mb-2 d-flex justify-content-between align-items-center">
            <IconArrowLeft onClick={handleClose} />
            <CountryDropdown className="mt-3- dd-country" />
          </div>
          <Nav as={'ul'} className="d-md-none h4">
            <Nav.Item as={'li'} className="border-bottom py-2">
              <Nav.Link
                className={`nav-header-link ${activeLink === 'how-it-works' ? 'active' : ''}`}
                to="/"
                onClick={() => handleMobileNavClick('how-it-works')}
              >
                How it Works
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item as={"li"} className="border-bottom py-2">
                            <Nav.Link
                                className={`nav-header-link ${activeLink === "testimonials" ? "active" : ""}`}
                                to="/"
                                onClick={() => handleMobileNavClick("testimonials")}
                            >
                                Testimonials
                            </Nav.Link>
                        </Nav.Item> */}
            {/* <Nav.Item as={"li"} className="border-bottom py-2">
                            <Nav.Link className={`nav-header-link ${activeLink === "pricing" ? "active" : ""}`} to="/" onClick={() => handleMobileNavClick("pricing")}>
                                Pricing
                            </Nav.Link>
                        </Nav.Item> */}
            <Nav.Item as={'li'} className="border-bottom py-2">
              <Nav.Link
                as={NavLink}
                className="nav-header-link fs-3"
                to="/features"
                onClick={handleClose}
              >
                Features
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as={'li'} className="border-bottom py-2">
              <Nav.Link
                as={NavLink}
                className="nav-header-link fs-3"
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  redirectTo('add-property');
                  handleClose();
                }}
              >
                <span className="text-dark">
                  {TEXT_CONSTANT.ADD_PROPERTY_BUTTON_TEXT}
                </span>
              </Nav.Link>
            </Nav.Item>
            {!authSelector.auth && (
              <Nav.Item as={'li'} className="py-2">
                <Nav.Link
                  as={NavLink}
                  className="nav-header-link fs-3"
                  to=""
                  onClick={() => {
                    handleShowLogin();
                    handleClose();
                  }}
                >
                  <span className="text-dark">
                    {TEXT_CONSTANT.LOGIN_BUTTON_TEXT}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MobileMenu;
