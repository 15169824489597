import React, { forwardRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { Badge } from 'react-bootstrap';
// import { Thumbnail } from "../../common/status";

const SelectBox = forwardRef(
  (
    {
      isCreatable,
      isAsync,
      showImage = false,
      showIcon = false,
      isTransparent = false,
      ...props
    },
    ref,
  ) => {
    const SelectType = isAsync
      ? AsyncSelect
      : isCreatable
        ? CreatableSelect
        : Select;
    const customStyles = {
      placeholder: (provided) => {
        return {
          ...provided,
          ...(isTransparent && { color: 'rgba(255, 255, 255, 0.6)' }),
        };
      },
      option: (provided, state) => {
        return {
          ...provided,
          // ...optionSettings,
          background: state.isSelected
            ? 'var(--tblr-primary)'
            : state.isFocused
              ? 'var(--tblr-gray-100)'
              : '',
          // background:(state.isSelected || state.isFocused) && "var(--tblr-gray-100)",
          // color:"inherit",
          color: state.isDisabled
            ? 'var(--tblr-muted)'
            : state.isSelected
              ? 'var(--tblr-white)'
              : state.isFocused
                ? isTransparent
                  ? 'var(--tblr-dark)'
                  : 'inherit'
                : 'var(--tblr-dark)',
        };
      },
      valueContainer: (provided, state) => ({
        ...provided,
        // ...(isTransparent && { color: "red" }),
        // padding:``,
      }),
      control: (provided, state) => {
        let hasError = state.selectProps?.className?.includes('is-invalid');
        return {
          ...provided,
          ...(isTransparent && { backgroundColor: 'transparent' }),
          minHeight: 'unset',
          minHeight: '36px',
          borderRadius: 'var(--tblr-border-radius)',
          boxShadow: `${hasError && state.isFocused ? '0 0 0 0.25rem rgb(214 57 57 / 25%)' : state.isFocused ? (!isTransparent ? '0 0 0 0.25rem rgb(42 106 115 / 25%)' : '0 0 0 0.25rem rgba(255, 255, 255, 0.25)') : ''}`,
          borderColor: `${hasError ? 'var(--tblr-danger)' : state.isFocused ? (!isTransparent ? '#90b5e2' : 'rgba(255, 255, 255, 0.5)') : 'var(--tblr-border-color)'}`,
          '&:hover': {
            borderColor: `${hasError ? 'var(--tblr-danger)' : state.isFocused ? (!isTransparent ? '#90b5e2' : 'rgba(255, 255, 255, 0.5)') : 'var(--tblr-border-color)'}`,
          },
        };
      },
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '6px',
        ...(isTransparent && {
          color: '#ffffff',
          '&:hover': { color: '#ffffff' },
        }),
      }),
      clearIndicator: (provided, state) => ({
        ...provided,
        padding: '6px',
        ...(isTransparent && {
          color: '#ffffff',
          '&:hover': { color: '#ffffff' },
        }),
      }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          // backgroundColor: 'rgba(var(--tblr-primary-rgb), 0.1)',
          // borderRadius:'4px',
          // border:'1px solid var(--tblr-primary)',
          // fontWeight:'var(--tblr-font-weight-medium)',
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        // color: data.color,
      }),
      // multiValueRemove: (styles, { data }) => ({
      //     ...styles,
      //     color: data.color,
      //     ':hover': {
      //         backgroundColor: data.color,
      //         color: 'white',
      //     },
      // })
    };
    const Option = ({ children, ...props }) => {
      const profileImage = props.data?.profile_image
        ? process.env.REACT_APP_UPLOAD_BASE_URL + props.data?.profile_image
        : '';
      return (
        <components.Option {...props}>
          <div className="d-flex">
            {/* {props.data?.profile_image && <Thumbnail url={profileImage} title={props.data.label} />} */}
            {children}
            {props.data?.icon && props.data.icon}
          </div>
        </components.Option>
      );
    };
    const ValueContainer = ({ children, ...props }) => {
      return (
        <components.ValueContainer {...props}>
          <div className="d-flex align-items-center">
            {props.selectProps?.value?.color && (
              <Badge
                bg=""
                className="p-2 me-1"
                style={{
                  background: `${props.selectProps?.value?.color}`,
                }}
              />
            )}
            {children}
            {props.selectProps?.value?.icon && props.selectProps.value.icon}
          </div>
        </components.ValueContainer>
      );
    };
    const customComponents = showIcon
      ? { Option, ValueContainer }
      : isCreatable
        ? {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }
        : {};
    return (
      <SelectType
        // components={{ DropdownIndicator, IndicatorSeparator, ...components }}
        // theme={getSelectTheme}
        components={customComponents}
        styles={customStyles}
        ref={ref}
        {...props}
      />
    );
  },
);
export default SelectBox;
