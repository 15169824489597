import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_AUTH_PREFIX;
const RECO_API_PREFIX = process.env.REACT_APP_RECO_API_URL;
export const getIpGeoLocation = async () => {
  return await axios
    .get(`${RECO_API_PREFIX}${API_PREFIX}location/get`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
