import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Utils from '../../Utils';
import { Slide, toast } from 'react-toastify';
import {
  IconCloudUpload,
  IconPencil,
  IconSquareRoundedMinus,
  IconUser,
} from '@tabler/icons-react';
import Loader from '../../components/Loader';
const ProfileImage = ({
  name,
  profileImage,
  setProfileImage,
  register,
  setValue,
  type = 'profile',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleFileInputChange = (e) => {
    let image = e.target.files[0];
    if (image) {
      Utils.getBase64Image(image)
        .then((result) => {
          setProfileImage(result);
        })
        .catch((error) => {
          setValue(name, null);
        });
    }
  };
  const handleDeleteProfilePhoto = async () => {
    setIsLoading(true);
    try {
      setProfileImage('');
      setValue(name, null);
      if (type === 'profile') {
        toast.success(
          `${type === 'profile' ? 'Profile image' : 'Company logo'} deleted successfully!`,
          { transition: Slide },
        );
      }
      // const { data } = await AuthApi.removeProfileImage();
      // if (data) {
      //     const updatedData = {
      //         ...authSelector,
      //         profile_image:""
      //     };
      //     setValue(name,null);
      //     setProfileImage("");
      //     dispatch(addAuthData(updatedData));

      // }
    } catch (error) {
      // handleApiError(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Row className="row-cards">
      <Form.Group as={Col} className={`${type === 'profile' ? 'mb-3' : ''}`}>
        {isLoading && <Loader />}
        <span
          className={`avatar avatar-xl ${
            type === 'profile'
              ? 'bg-primary-lt border-primary'
              : 'border-1 bg-white border-dashed border-primary shadow-none'
          }`}
          style={{
            backgroundImage: profileImage ? `url('${profileImage}')` : 'none',
          }}
        >
          {!profileImage && type === 'profile' ? (
            <IconUser className="icon icon-sm" />
          ) : (
            !profileImage && (
              <div className="fs-5 fw-normal text-dark text-capitalize ">
                <IconCloudUpload className="icon text-primary d-inline-block w-4 h-4 " />
                <br />
                Upload Logo
              </div>
            )
          )}
        </span>
        <Button
          variant="secondary"
          size="sm"
          className="btn-icon rounded-circle position-absolute "
          style={{
            zIndex: 1,
            marginLeft: '-29px',
            marginTop: '83px',
          }}
        >
          <IconPencil stroke={1.5} size={16} />
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={handleDeleteProfilePhoto}
          className={`btn-icon rounded-circle position-absolute ${!profileImage ? 'd-none' : ''}`}
          style={{
            zIndex: 1,
            marginLeft: '-106px',
            marginTop: '83px',
          }}
        >
          <IconSquareRoundedMinus stroke={1.5} size={16} />
        </Button>
        <input
          type="file"
          className="-d-none btn-icon position-absolute opacity-0"
          style={{
            zIndex: 1,
            width: '24px',
            marginLeft: '-29px',
            marginTop: '83px',
          }}
          accept="image/png, image/gif, image/jpeg"
          {...register(name)}
          onChange={handleFileInputChange}
        />
      </Form.Group>
    </Row>
  );
};

export default ProfileImage;
