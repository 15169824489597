import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_WEB_PREFIX;

export const getPropertyForLease = async () => {
  return await axios
    .get(`${API_PREFIX}dashboard/property-for-lease`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getPropertyForSale = async () => {
  return await axios
    .get(`${API_PREFIX}dashboard/property-for-sale`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getProvinceList = async () => {
  return await axios
    .get(`${API_PREFIX}dashboard/provinces/get`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
