import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import Layout from './components/Layout';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID, // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);
let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>
);
