import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cities: [],
};

const citiesSlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    addCities: (state, action) => {
      state.cities = action.payload;
    },
    resetCities: (state) => {
      state.cities = [];
    },
  },
});
export const { addCities, resetCities } = citiesSlice.actions;
export default citiesSlice.reducer;
