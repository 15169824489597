import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export const Page = (props) => {
  return <div className="page">{props.children}</div>;
};
export const PageWrapper = (props) => {
  return <div className="page-wrapper">{props.children}</div>;
};

export const PageHeader = ({ title, children }) => {
  return (
    <div className="page-header mt-0 d-print-none">
      {title && (
        <Helmet>
          <title>
            {title} | {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {children}
    </div>
  );
};
export const PageBody = ({ children, useContainer, className = '' }) => {
  const bodyClass = useContainer ? 'page-body with-container' : 'page-body';

  return (
    <div className={`${bodyClass} ${className}`}>
      {useContainer ? <Container fluid="xl">{children}</Container> : children}
    </div>
  );
};
export const PageHelmet = ({ title }) => {
  if (!title) return;
  return (
    <Helmet>
      <title>
        {title} | {process.env.REACT_APP_NAME}
      </title>
    </Helmet>
  );
};
