import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  city: '',
  province: '',
  country: 'CA',
  ip: '',
};
const ipGeolocationSlice = createSlice({
  name: 'ipGeolocation',
  initialState,
  reducers: {
    setIpGeolocation: (state, action) => {
      state.city = action.payload.city;
      state.province = action.payload.province;
      state.country = action.payload.country;
      state.ip = action.payload.ip;
    },
    resetIpGeolocation: () => initialState,
  },
});

export const { setIpGeolocation, resetIpGeolocation } =
  ipGeolocationSlice.actions;
export default ipGeolocationSlice.reducer;
