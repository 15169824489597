import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema } from './MyProfileValidation';
import CustomModal from '../../components/CustomModal/CustomModal';
import { FormFieldPassword } from '../../components/Form/FormField';

import { Slide, toast } from 'react-toastify';
const ChangePassword = ({ changePasswordModal, setChangePasswordModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  let defaultChangePasswordValue = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors: changePasswordError },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(changePasswordSchema),
    defaultValues: defaultChangePasswordValue,
  });
  const formSubmit = async (formData) => {
    setIsLoading(true);
    toast.success('Password updated successfully!', { transition: Slide });
    setTimeout(() => {
      setChangePasswordModal(false);
    }, 1000);
  };

  return (
    <CustomModal
      show={changePasswordModal}
      isLoading={isLoading}
      onHide={() => setChangePasswordModal(false)}
      modalHeading={'Change Password'}
      saveBtnText={'Update Password'}
      onSaveCallback={handleSubmit(formSubmit)}
      hasClose={false}
    >
      <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
        <Row className="row-cards mb-3">
          <FormFieldPassword
            label="Current Password"
            name="current_password"
            type="text"
            error={changePasswordError?.current_password}
            register={register}
            placeholder="Enter current password"
            autoFocus
            required
            size="12"
          />
          <FormFieldPassword
            label="New Password"
            name="new_password"
            type="text"
            error={changePasswordError?.new_password}
            register={register}
            placeholder="Enter new password"
            autoFocus
            required
            size="12"
          />
          <FormFieldPassword
            label="Confirm Password"
            name="confirm_password"
            type="password"
            error={changePasswordError?.confirm_password}
            register={register}
            placeholder="Enter confirm password"
            required
            size="12"
          />
        </Row>
      </Form>
    </CustomModal>
  );
};

export default ChangePassword;
