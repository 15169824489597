import React from 'react';
import * as Yup from 'yup';
import {
  ALPHABETIC_REQUIRED_FIELD,
  EMAIL_FIELD,
  PASSWORD,
  PHONE_FIELD,
  REQUIRED_FIELD,
} from '../../common/validation';
export const loginSchema = Yup.object().shape({
  email: EMAIL_FIELD,
  password: REQUIRED_FIELD,
});
export const signupSchema = Yup.object().shape({
  first_name: ALPHABETIC_REQUIRED_FIELD,
  last_name: ALPHABETIC_REQUIRED_FIELD,
  address: REQUIRED_FIELD,
  email: EMAIL_FIELD,
  phone: PHONE_FIELD,
  password: PASSWORD,
  confirm_password: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Both passwords must match!',
  ),
});
export const forgotPasswordSchema = Yup.object().shape({
  email: EMAIL_FIELD,
});
export const resetPasswordSchema = Yup.object().shape({
  password: PASSWORD,
  confirm_password: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Both passwords must match!',
  ),
});
