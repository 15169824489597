import React from 'react';
import { Container } from 'react-bootstrap';
import LOGO from '../../assets/images/favicon.png';
const PageLoader = ({ text }) => {
  return (
    <div className="page page-center">
      <Container className="container-slim py-4">
        <div className="text-center">
          <div className="mb-3">
            <a href="#" className="navbar-brand navbar-brand-autodark">
              <img src={LOGO} height="" alt="" />
            </a>
          </div>
          {text && <div className="text-muted mb-3">{text}</div>}
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PageLoader;
