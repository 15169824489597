import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import LOGO from '../../assets/images/logo.svg';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import HeaderDropdown from './HeaderDropdown';
import Menu from './Menu';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import Login from '../../pages/Authentication/Login';
import Signup from '../../pages/Authentication/Signup';
import ForgotPassword from '../../pages/Authentication/ForgotPassword';
import {
  hideLoginModal,
  showLoginModal,
} from '../../store/slices/authUser/loginModalSlice';
import { TEXT_CONSTANT } from '../../common/constants';
import useIsMobile from '../../hooks/useIsMobile';
import MobileMenu from './MobileMenu';
import CountryDropdown from '../Form/CountryDropdown';
const DEFAULT_LOGIN_MODAL = {
  show: false,
  title: 'Login',
  saveBtnText: 'Login',
};
const DEFAULT_SIGNUP_MODAL = {
  show: false,
  title: 'Sign Up',
  saveBtnText: 'Sign Up',
};
const DEFAULT_FORGOT_PASSWORD_MODAL = {
  show: false,
  title: 'Forgot Password',
  saveBtnText: 'Send',
};
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const isMobile = useIsMobile();
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const isLoginModalVisible = useSelector(
    (state) => state.rcsl.loginModalReducer.showLoginModal,
  );

  const [loginModal, setLoginModal] = useState(DEFAULT_LOGIN_MODAL);
  const [signupModal, setSignupModal] = useState(DEFAULT_SIGNUP_MODAL);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(
    DEFAULT_FORGOT_PASSWORD_MODAL,
  );

  // const queryParams = new URLSearchParams(location.search);
  // const showLoginParam = queryParams.get("showLogin");

  const handleShowLogin = () => {
    dispatch(
      showLoginModal({
        login: true,
        signup: false,
      }),
    );
  };
  const resetLoginModal = () => {
    dispatch(
      dispatch(
        showLoginModal({
          ...isLoginModalVisible,
          login: false,
        }),
      ),
    );
  };
  const resetSignupModal = () => {
    // setSignupModal(DEFAULT_SIGNUP_MODAL);
    dispatch(
      showLoginModal({
        ...isLoginModalVisible,
        signup: false,
      }),
    );
  };
  const resetForgotPasswordModal = () => {
    setForgotPasswordModal(DEFAULT_FORGOT_PASSWORD_MODAL);
  };
  const redirectTo = (url) => {
    if (authSelector.auth) {
      navigate(`/${url}`);
    } else {
      handleShowLogin();
    }
  };
  useEffect(() => {
    setLoginModal((preveState) => ({
      ...preveState,
      show: isLoginModalVisible.login,
    }));
    setSignupModal((preveState) => ({
      ...preveState,
      show: isLoginModalVisible.signup,
    }));
  }, [isLoginModalVisible]);
  const [activeLink, setActiveLink] = useState(null);
  const sections = ['how-it-works', 'testimonials', 'pricing'];

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sectionOffsets = sections
      .map((sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          return {
            id: sectionId,
            offsetTop: sectionElement.offsetTop,
            offsetBottom:
              sectionElement.offsetTop + sectionElement.offsetHeight,
          };
        }
        return null;
      })
      .filter(Boolean);

    let activeSection = null;
    for (const section of sectionOffsets) {
      if (
        scrollPosition >= section.offsetTop - 100 &&
        scrollPosition < section.offsetBottom - 50
      ) {
        activeSection = section.id;
        break;
      }
    }

    setActiveLink(activeSection);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const isHome = window.location.pathname === '/';
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      const { top } = targetElement.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + top - 50, // Adjust the offset as needed
        behavior: 'smooth',
      });
    } else {
      navigate('/');
      setTimeout(
        () => {
          const targetElement = document.getElementById(sectionId);
          if (targetElement) {
            const { top } = targetElement.getBoundingClientRect();
            window.scrollTo({
              top: window.scrollY + top - 50, // Adjust the offset as needed
              behavior: 'smooth',
            });
          }
          setTimeout(
            () => {
              const targetElement = document.getElementById(sectionId);
              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop - 50,
                  behavior: 'smooth',
                });
              }
            },
            isHome ? 0 : 500,
          );
        },
        isHome ? 100 : 500,
      );
    }
  };

  const handleNavClick = (sectionId) => {
    setActiveLink(sectionId);
    scrollToSection(sectionId);
  };
  return (
    <Navbar
      as="header"
      expand="md"
      className={`${authSelector?.device === 'mobile' ? 'd-none' : ''} d-lg-flex d-print-none sticky-top`}
      style={{ minHeight: '5rem' }}
    >
      <Container fluid="xl">
        {/* <Navbar.Toggle /> */}
        {isMobile && (
          <MobileMenu
            {...{
              handleShowLogin,
              redirectTo,
              activeLink,
              handleNavClick: handleNavClick,
            }}
          />
        )}
        <Navbar.Brand
          as={'h1'}
          className="navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"
        >
          <Link to={'/'}>
            <img src={LOGO} alt="Logo" className="navbar-brand-image" />
          </Link>
        </Navbar.Brand>
        <Nav as={'ul'} className="d-none d-md-flex order-md-last">
          <Nav.Item as={'li'}>
            <Nav.Link
              className={`nav-header-link ${activeLink === 'how-it-works' ? 'active' : ''}`}
              to="/"
              onClick={(e) => handleNavClick('how-it-works')}
            >
              How it Works
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item as={"li"}>
                        <Nav.Link className={`nav-header-link ${activeLink === "testimonials" ? "active" : ""}`} to="/" onClick={() => handleNavClick("testimonials")}>
                            Testimonials
                        </Nav.Link>
                    </Nav.Item> */}
          <Nav.Item as={'li'}>
            <Nav.Link
              className={`nav-header-link ${activeLink === 'pricing' ? 'active' : ''}`}
              to="/"
              onClick={() => handleNavClick('pricing')}
            >
              Pricing
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as={'li'}>
            <Nav.Link as={NavLink} className="nav-header-link" to="/features">
              Features
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav as={'div'} className="flex-row d-md-none order-md-last">
          {authSelector.auth && <HeaderDropdown isMobile />}
        </Nav>
        <Nav className="flex-row order-md-last ms-2 d-none d-md-flex">
          <Nav className="d-none d-md-flex">
            <Button
              variant="outline-primary"
              className="me-3"
              onClick={(e) => redirectTo('add-property')}
            >
              {TEXT_CONSTANT.ADD_PROPERTY_BUTTON_TEXT}{' '}
              <IconArrowNarrowRight className="icon ms-1 me-0 " />
            </Button>
            {authSelector.auth ? (
              <HeaderDropdown />
            ) : (
              <Button variant="primary" onClick={handleShowLogin}>
                {TEXT_CONSTANT.LOGIN_BUTTON_TEXT}{' '}
                <IconArrowNarrowRight className="icon ms-1 me-0 " />
              </Button>
            )}
            <CountryDropdown className="ms-3 dd-country" />
          </Nav>
        </Nav>
        <Navbar.Collapse></Navbar.Collapse>
        {loginModal.show && (
          <Login
            {...{
              loginModal,
              resetLoginModal,
              setSignupModal,
              setForgotPasswordModal,
            }}
          />
        )}
        {signupModal.show && <Signup {...{ signupModal, resetSignupModal }} />}
        {forgotPasswordModal.show && (
          <ForgotPassword
            {...{ forgotPasswordModal, resetForgotPasswordModal }}
          />
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
