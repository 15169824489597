// searchSlice.js
import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    formData: {},
  },
  reducers: {
    setSearchFormData(state, action) {
      state.formData = action.payload;
    },
  },
});

export const { setSearchFormData } = searchSlice.actions;
export default searchSlice.reducer;
