import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import FooterLogo from '../../assets/images/footer-logo.svg';
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandYoutube,
} from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { showLoginModal } from '../../store/slices/authUser/loginModalSlice';
import {
  CONTACT_EMAIL,
  CONTACT_PHONE,
  TEXT_CONSTANT,
} from '../../common/constants';
import * as DashboardAPI from '../../api/DashboardAPI';
import { addCities } from '../../store/slices/cities/popularCitiesSlice';
import FB from '../../assets/images/social-icons/FB.svg';
import LinkedIn from '../../assets/images/social-icons/LinkedIn.svg';
import Twitter from '../../assets/images/social-icons/Twitter.svg';
import YT from '../../assets/images/social-icons/YT.svg';
import AddressIcon from '../../assets/images/footer/address.svg';
import EmailIcon from '../../assets/images/footer/email.svg';
import PhoneIcon from '../../assets/images/footer/phone.svg';
import Utils from '../../Utils';
const Footer = () => {
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const ipGeoLocationSelector = useSelector(
    (state) => state.rcsl.ipGeolocationReducer,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popularAreas, setPopularAreas] = useState([]);
  const redirectTo = (to, requiredAuth) => {
    if (requiredAuth) {
      if (authSelector.auth) {
        navigate(to);
      } else {
        dispatch(
          showLoginModal({
            login: true,
            signup: false,
          }),
        );
      }
    } else {
      navigate(to);
    }
  };
  const quickLinks = [
    {
      title: TEXT_CONSTANT.ADD_PROPERTY_BUTTON_TEXT,
      to: '/add-property',
      requiredAuth: true,
    },
    // { title: "Featured Sale Properties", to: "/featured-sale-properties" },
    { title: 'Featured Lease Properties', to: '/featured/lease' },
    { title: 'Top Cities', to: '' },
    // { title: "Rent Reports", to: "" },
    // { title: "Sale Reports", to: "" },
  ];
  const fetchProvinceLists = async () => {
    try {
      const response = await DashboardAPI.getProvinceList();
      if (response && response?.data) {
        const refineProvince = response?.data?.sort(
          (a, b) => b.total_properties - a.total_properties,
        );
        const topProvinces = refineProvince.slice(0, 5);

        setPopularAreas(topProvinces);
        dispatch(addCities(topProvinces));
      } else {
        throw new Error('Area deals data not available');
      }
    } catch (error) {
      setPopularAreas([]);
    }
  };
  useEffect(() => {
    fetchProvinceLists();
  }, [ipGeoLocationSelector]);
  const renderTermAndCondition = () => {
    return (
      <div className="text-center text-md-end h3 mb-0 fw-normal">
        <Link to={'/terms-and-conditions'} className="text-light">
          Terms & Conditions
        </Link>
        <span className="mx-2 fw-bold">|</span>
        <Link to={'/privacy-policy'} className="text-light">
          Privacy Policy
        </Link>
      </div>
    );
  };
  return (
    <footer
      className={`${authSelector?.device === 'mobile' ? 'd-none' : ''} footer bg-primary dual-color-section py-3 text-light d-print-none overflow-hidden`}
    >
      <Container fluid="xl" className="pt-3 mt-3">
        <Row className="g-5">
          <Col lg={4} md={6} className="mb-2">
            <img src={FooterLogo} alt="logo" className="mb-3" />
            <h4>Powered by Resident Connect Inc.</h4>
            <p className="text-opacity-80">
              Look4Lease provides a distinctive platform facilitating direct
              communication between renters and property owners, bypassing the
              need for intermediaries like brokers or agents.
            </p>
            <div className="mb-2 text-opacity-80">Followed us on</div>
            <div>
              <Link target="_blank" to={''} className="text-light me-3">
                {/* <IconBrandFacebook className="icon" /> */}
                <img src={FB} alt="Facebook" />
              </Link>
              <Link target="_blank" to={''} className="text-light me-3">
                {/* <IconBrandLinkedin className="icon" /> */}
                <img src={LinkedIn} alt="LinkedIn" />
              </Link>
              <Link target="_blank" to={''} className="text-light me-3">
                {/* <IconBrandYoutube className="icon" /> */}
                <img src={YT} alt="YouTube" />
              </Link>
              <Link target="_blank" to={''} className="text-light">
                {/* <IconBrandTwitter className="icon" /> */}
                <img src={Twitter} alt="Twtter" />
              </Link>
            </div>
          </Col>
          <Col lg={4} md={12} className="mb-2">
            <Row>
              <Col className="mb-2">
                <h2>Quick Links</h2>
                <ul>
                  {quickLinks.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <a
                          className="text-light text-opacity-80 d-block mb-2"
                          onClick={() =>
                            redirectTo(item.to, item?.requiredAuth)
                          }
                          role="button"
                        >
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Col>
              <Col className="mb-2">
                <h2>Popular Areas</h2>
                <ul>
                  {popularAreas.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <Link
                          to={`/area/${item.province_slug}`}
                          className="text-light text-opacity-80 d-block mb-2"
                        >
                          {item.province_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={6} className="mb-2 ">
            <div className="contact position-relative z-2">
              <h2>Contact Us</h2>
              <Row className="">
                <Col bsPrefix="col-auto">
                  <img src={AddressIcon} alt="address" />
                </Col>
                <Col>
                  <span className="text-opacity-80">Address</span>
                  <p className="fw-bolder">
                    302-2265 Pembina Hwy, <br />
                    Winnipeg, MB, R3T 2H1
                  </p>
                </Col>
              </Row>
              <div className="d-flex align-items-start">
                <img src={EmailIcon} alt="email" className="me-3" />
                <div className="mb-3">
                  <span className="text-opacity-80">Email</span>
                  <Link
                    target="_blank"
                    to={`mailto:${CONTACT_EMAIL}`}
                    className="text-light fw-bolder d-block"
                  >
                    {CONTACT_EMAIL}
                  </Link>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <img src={PhoneIcon} alt="phone" className="me-3" />
                <div className="mb-3">
                  <span className="text-opacity-80">Call</span>
                  <Link
                    target="_blank"
                    to={`tel:${CONTACT_PHONE}`}
                    className="text-light fw-bolder d-block"
                  >
                    {Utils.formatPhoneNumber(CONTACT_PHONE)}
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <hr className="my-3" />
      <Container fluid="xl">
        <Row>
          <Col md={6} className="text-center text-md-start">
            <p className="mb-0 h3 fw-normal">
              &copy; {new Date().getFullYear()} Look4Lease (Powered by Resident
              Connect Inc.). All Rights Reserved.
            </p>
          </Col>
          <Col md={6}>{renderTermAndCondition()}</Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
