import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Form, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { myProfileSchema } from './MyProfileValidation';
import CustomModal from '../../components/CustomModal/CustomModal';
import { FormField, FormFieldAddress } from '../../components/Form/FormField';

import { Slide, toast } from 'react-toastify';
import ProfileImage from './ProfileImage';
import Utils from '../../Utils';
import * as AuthAPI from '../../api/AuthAPI';
import { handleApiError } from '../../common/errorHandler';
import { addAuthData } from '../../store/slices/authUser/authUserSlice';
import { ROLES } from '../../common/constants';
const MyProfile = ({ myProfileModal, setMyProfileModal }) => {
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(
    authSelector.profile_image
      ? process.env.REACT_APP_UPLOAD_BASE_URL + authSelector.profile_image
      : '',
  );
  let defaultMyProfileValue = {
    role: authSelector.role,
    first_name: authSelector.first_name,
    last_name: authSelector.last_name,
    email: authSelector.email,
    phone: authSelector.phone,
    profile: null,
    address: authSelector.address,
    zipcode: authSelector.zipcode,
    city: authSelector.city,
    province: authSelector.province,
    country: authSelector.country,
    latitude: authSelector.latitude,
    longitude: authSelector.longitude,
  };
  if (authSelector.role === ROLES.COMPANY) {
    defaultMyProfileValue = {
      ...defaultMyProfileValue,
      company_name: authSelector.company_name || '',
    };
  }
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors: myProfileError },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(myProfileSchema),
    defaultValues: defaultMyProfileValue,
  });
  const formSubmit = async (formData) => {
    delete formData.email;
    if (!formData?.profile) {
      delete formData.profile;
    }
    let refineFormData = Utils.getFormData(formData);
    setIsLoading(true);
    try {
      const { data } = await AuthAPI.updateProfile(refineFormData);
      if (data) {
        let { company_name, first_name, last_name, phone, profile_image } =
          data;
        let updatedData = {
          ...authSelector,
          first_name: first_name,
          last_name: last_name,
          phone: phone,
          profile_image: profile_image,
          address: formData.address,
          zipcode: formData.zipcode,
          city: formData.city,
          province: formData.province,
          country: formData.country,
          latitude: formData.latitude,
          longitude: formData.longitude,
        };
        if (authSelector.role === ROLES.COMPANY) {
          updatedData = {
            ...updatedData,
            company_name: company_name || formData.company_name,
          };
        }
        dispatch(addAuthData(updatedData));
        setMyProfileModal(false);
        toast.success('Profile updated successfully!', {
          transition: Slide,
        });
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <CustomModal
      show={myProfileModal}
      isLoading={isLoading}
      onHide={() => setMyProfileModal(false)}
      modalHeading={'My Profile'}
      saveBtnText={'Update Profile'}
      onSaveCallback={handleSubmit(formSubmit)}
      hasClose={false}
    >
      <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
        <ProfileImage
          {...{
            name: 'profile',
            profileImage,
            setProfileImage,
            register,
            setValue,
          }}
        />
        <Row className="row-cards mb-3">
          {authSelector.role === ROLES.COMPANY && (
            <FormField
              label="Company Name"
              name="company_name"
              type="text"
              error={myProfileError?.company_name}
              register={register}
              placeholder="Enter company name"
              autoFocus
              required
              size="12"
            />
          )}
          <FormField
            label="First Name"
            name="first_name"
            type="text"
            error={myProfileError?.first_name}
            register={register}
            placeholder="Enter first name"
            autoFocus
            required
          />

          <FormField
            label="Last Name"
            name="last_name"
            type="text"
            error={myProfileError?.last_name}
            register={register}
            placeholder="Enter last name"
            required
          />
          <FormFieldAddress
            label="Address"
            name="address"
            error={myProfileError.address}
            register={register}
            control={control}
            setValue={setValue}
            size="12"
            trigger={trigger}
            hasDetails={true}
            required
          />
          <FormField
            label="Email"
            name="email"
            type="text"
            error={myProfileError?.email}
            register={register}
            placeholder="Enter email"
            required
            disabled={true}
            size={12}
          />
          <FormField
            label="Phone"
            name="phone"
            type="number"
            error={myProfileError?.phone}
            register={register}
            placeholder="Enter phone"
            required
            size={12}
          />
        </Row>
      </Form>
    </CustomModal>
  );
};

export default MyProfile;
