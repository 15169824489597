import moment from 'moment';
import 'moment-timezone';
import PropertyPlaceholder from '../assets/images/placeholder/property_placeholder.svg';
class Utils {
  static getAcronym = (name) => {
    if (!name || typeof name.trim !== 'function' || name.trim().length === 0) {
      return ''; // Return a default value when the name is empty
    }

    const filteredName = name.split(' ').filter((item) => item.trim() !== '');
    let res = '';

    if (filteredName.length < 2) {
      res = (filteredName[0][0] || '') + (filteredName[0][1] || '');
    } else {
      let i = 0;
      filteredName.forEach((item) => {
        const [char] = item;
        i++;
        if (i < 3) {
          res += char;
        }
      });
    }

    return res.toUpperCase();
  };
  static priceFormat = (price, includeDecimals = false) => {
    if (isNaN(price)) {
      return '$0';
    }
    const dollarUS = Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      // currencyDisplay: "code",
      minimumFractionDigits: includeDecimals ? 2 : 0,
    });
    return dollarUS.format(price);
  };
  static phoneFormat = (phoneNumber) => {
    if (!phoneNumber || phoneNumber === null || phoneNumber == 'null') {
      return null;
    }
    const formattedPhoneNumber =
      phoneNumber.length === 10
        ? `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
        : `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(1, 4)}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
    return formattedPhoneNumber;
  };
  static dateFormat(date, format = 'YYYY-MM-DD') {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return null;
    }

    const formattedDate = momentDate.format(format);
    return formattedDate;
  }
  static ucFirst(str) {
    if (typeof str !== 'string' || str.trim() === '') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  static getBase64Image = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  static getFormData = (object, isDocuments = false, mediaFields = []) => {
    const formData = new FormData();
    for (const key in object) {
      const value = object[key];
      if (Array.isArray(value)) {
        // value.forEach((item) => formData.append(`${key}${!isDocuments ? "[]" : ""}`, item));
        if (isDocuments && mediaFields.includes(key)) {
          value.forEach((item) => formData.append(key, item));
        } else {
          value.forEach((item) => formData.append(`${key}[]`, item));
        }
      } else if (value instanceof FileList) {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  };
  static getTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  };
  static combineDateTime(date, time) {
    const dateMoment = moment(date).format('YYYY-MM-DD');
    return `${dateMoment} ${time}`;
  }
  static localToUTC(localDate) {
    const timeZone = this.getTimeZone();
    return moment.tz(localDate, timeZone).utc().format();
  }
  static utcToLocal(utcDate) {
    const timeZone = this.getTimeZone();
    return moment.utc(utcDate).tz(timeZone).format();
  }
  static objectToQueryString(obj) {
    const queryString = Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
      )
      .join('&');
    return queryString;
  }
  static formatBedsBathValue = (value) => {
    if (!value) {
      return '0';
    } else if (typeof value === 'number') {
      return value.toString();
    } else if (
      typeof value === 'object' &&
      value.hasOwnProperty('min') &&
      value.hasOwnProperty('max')
    ) {
      if (value.min === null && value.max === null) {
        return '0';
      } else if (value.min === value.max) {
        return value.max.toString();
      } else {
        return `${value.min || 0}-${value.max || 0}`;
      }
    } else {
      return 'NA';
    }
  };
  static formatLabel = (value, label) => {
    const formattedValue = Utils.formatBedsBathValue(value);
    const isRange = formattedValue.includes('-');
    const numericValue = isRange ? null : parseInt(formattedValue, 10);
    const suffix = isRange || numericValue > 1 ? 's' : '';
    return `${formattedValue} ${label}${suffix}`;
  };
  static formatPriceValue(price) {
    if (!price) {
      return 'NA';
    } else if (typeof price === 'number') {
      return Utils.priceFormat(price);
    } else if (
      typeof price === 'object' &&
      price.min !== undefined &&
      price.max !== undefined
    ) {
      if (price.min === price.max) {
        return Utils.priceFormat(price.max);
      } else {
        const formattedMin = Utils.priceFormat(price.min);
        const formattedMax = Utils.priceFormat(price.max);
        return `${formattedMin}-${formattedMax}`;
      }
    } else {
      return 'Invalid value';
    }
  }
  static formatSquareFeet(area) {
    return `${area} sq ft`;
  }
  static getOrdinal(number) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return number + 'th';
    }

    return number + (suffixes[lastDigit] || suffixes[0]);
  }
  static getPropertyImageURL(image) {
    let refineImage = PropertyPlaceholder;
    if (image) {
      refineImage = `${process.env.REACT_APP_UPLOAD_BASE_URL}${image}`;
    }
    return refineImage;
  }
  static formatDateDistance = (dateString) => {
    if (!dateString) return null; // Return null if no date provided

    const date = moment(dateString);
    return date.isValid() ? date.fromNow() : null;
  };
  static daysBetween(givenDate) {
    const todayDate = moment().startOf('day');
    const endDate = moment(givenDate).startOf('day');
    const dayDifference = endDate.diff(todayDate, 'days');
    return dayDifference;
  }
  static formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    return phoneNumber;
  }
  static formatUnitIdentifier(block_no, unit_no) {
    if (!unit_no) {
      return '';
    }
    return block_no ? `${block_no}-${unit_no}` : `${unit_no}`;
  }
}
export default Utils;
