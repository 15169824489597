// CountryDropdown.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { setIpGeolocation } from '../../store/slices/geoLocation/ipGeoLocationSlice';
// import Flag from 'react-world-flags';

const countryOptions = [
  {
    value: 'CA',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="dropdown-item-indicator">
          <span className="flag flag-xs flag-country-ca ms-1"></span>
        </span>
        CA
      </div>
    ),
  },
  {
    value: 'US',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="dropdown-item-indicator">
          <span className="flag flag-xs flag-country-us ms-1"></span>
        </span>
        US
      </div>
    ),
  },
];

const DropdownIndicator = () => null;
const IndicatorSeparator = () => null;

const customComponents = {
  DropdownIndicator,
  IndicatorSeparator,
};
const CountryDropdown = ({ isTransparent = false, ...props }) => {
  const dispatch = useDispatch();
  const ipGeoLocationSelector = useSelector(
    (state) => state.rcsl.ipGeolocationReducer,
  );
  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.find(
      (option) => option.value === ipGeoLocationSelector?.country,
    ),
  );

  const customStyles = {
    placeholder: (provided) => {
      return {
        ...provided,
        ...(isTransparent && { color: 'rgba(255, 255, 255, 0.6)' }),
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        // ...optionSettings,
        background: state.isSelected
          ? 'var(--tblr-primary)'
          : state.isFocused
            ? 'var(--tblr-gray-100)'
            : '',
        // background:(state.isSelected || state.isFocused) && "var(--tblr-gray-100)",
        // color:"inherit",
        color: state.isDisabled
          ? 'var(--tblr-muted)'
          : state.isSelected
            ? 'var(--tblr-white)'
            : state.isFocused
              ? isTransparent
                ? 'var(--tblr-dark)'
                : 'inherit'
              : 'var(--tblr-dark)',
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      // ...(isTransparent && { color: "red" }),
      // padding:``,
    }),
    control: (provided, state) => {
      let hasError = state.selectProps?.className?.includes('is-invalid');
      return {
        ...provided,
        ...(isTransparent && { backgroundColor: 'transparent' }),
        minHeight: 'unset',
        minHeight: '36px',
        borderRadius: 'var(--tblr-border-radius)',
        boxShadow: `${
          hasError && state.isFocused
            ? '0 0 0 0.25rem rgb(214 57 57 / 25%)'
            : state.isFocused
              ? !isTransparent
                ? '0 0 0 0.25rem rgb(42 106 115 / 25%)'
                : '0 0 0 0.25rem rgba(255, 255, 255, 0.25)'
              : ''
        }`,
        borderColor: `${hasError ? 'var(--tblr-danger)' : state.isFocused ? (!isTransparent ? '#90b5e2' : 'rgba(255, 255, 255, 0.5)') : 'var(--tblr-border-color)'}`,
        '&:hover': {
          borderColor: `${
            hasError
              ? 'var(--tblr-danger)'
              : state.isFocused
                ? !isTransparent
                  ? '#90b5e2'
                  : 'rgba(255, 255, 255, 0.5)'
                : 'var(--tblr-border-color)'
          }`,
        },
      };
    },
  };
  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    dispatch(
      setIpGeolocation({
        city: '',
        province: '',
        country: selectedOption.value,
        ip: ipGeoLocationSelector.ip,
      }),
    );
  };
  useEffect(() => {
    let defaultCountry = countryOptions.find(
      (option) => option.value === ipGeoLocationSelector.country,
    );
    console.log("🚀 ~ useEffect ~ defaultCountry:", defaultCountry)
    if (!defaultCountry) {
      defaultCountry = countryOptions.find((option) => option.value === 'CA');
    }
    setSelectedCountry(defaultCountry);
  }, [ipGeoLocationSelector]);
  return (
    <Select
      {...props}
      options={countryOptions}
      onChange={handleChange}
      value={selectedCountry}
      styles={customStyles}
      isSearchable={false}
    />
  );
};

export default CountryDropdown;
