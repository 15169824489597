import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import CustomModal from '../../components/CustomModal/CustomModal';
import { FormField, FormFieldPassword } from '../../components/Form/FormField';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPasswordSchema } from './AuthSchema';
import * as AuthAPI from '../../api/AuthAPI';
import { addAuthData } from '../../store/slices/authUser/authUserSlice';
import { showLoginModal } from '../../store/slices/authUser/loginModalSlice';
const ForgotPassword = ({ forgotPasswordModal, resetForgotPasswordModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show, title, saveBtnText, action } = forgotPasswordModal;
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors: forgotPasswordError },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordSchema),
  });
  const formSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const { data } = await AuthAPI.forgotPassword({
        email: formData.email,
        rentals: true,
      });
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
      const message = error?.message?.email?.message
        ? error.message.email.message
        : error.message;
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleShowLoginModal = () => {
    dispatch(
      showLoginModal({
        login: true,
        signup: false,
      }),
    );
    resetForgotPasswordModal();
  };
  return (
    <CustomModal
      show={show}
      isLoading={isLoading}
      onHide={() => resetForgotPasswordModal(false)}
      modalHeading={title}
      saveBtnText={saveBtnText}
      onSaveCallback={handleSubmit(formSubmit)}
      hasActions={false}
    >
      {isSuccess && (
        <div className="text-center w-100">
          {/* <img src={MailboxIcon} alt="" /> */}
          <h1 className="mt-2  text-primary">Check your Email</h1>
          <p className="text-muted">
            An email has been sent to <strong>{getValues('email')}</strong>.
            Please click
            <strong> on the included linkto reset your password.</strong>
          </p>
          <Link
            to=""
            className="btn btn-primary w-100 fw-bold"
            onClick={handleShowLoginModal}
          >
            Back to Login
          </Link>
        </div>
      )}
      {!isSuccess && (
        <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
          {isError && (
            <Alert variant="danger" className="p-2">
              {errorMessage}
            </Alert>
          )}
          <Row className="row-cards mb-3">
            <FormField
              label="Email"
              name="email"
              error={forgotPasswordError?.email}
              register={register}
              size={12}
              required
              autoFocus
            />
          </Row>
          <Row className="align-items-center">
            <Col>
              <Button variant="primary" type="submit">
                {saveBtnText}{' '}
                <IconArrowNarrowRight className="icon ms-1 me-0 " />
              </Button>
            </Col>
            <Col className="text-end">
              Already have an account?{' '}
              <Link to={''} className="fw-bold" onClick={handleShowLoginModal}>
                Login
              </Link>
            </Col>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default ForgotPassword;
