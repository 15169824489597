import { combineReducers } from 'redux';
import authUserReducer from './slices/authUser/authUserSlice';
import loginModalReducer from './slices/authUser/loginModalSlice';
import citiesReducer from './slices/cities/popularCitiesSlice';
import searchReducer from './slices/search/searchSlice';
import ipGeolocationReducer from './slices/geoLocation/ipGeoLocationSlice';
export default combineReducers({
  authUserReducer,
  loginModalReducer,
  citiesReducer,
  searchReducer,
  ipGeolocationReducer,
});
