import * as Yup from 'yup';
import {
  ALPHABETIC_REQUIRED_FIELD,
  EMAIL_FIELD,
  PASSWORD,
  PHONE_FIELD,
  PHOTO_FIELD,
  REQUIRED_FIELD,
} from '../../common/validation';
import { ROLES } from '../../common/constants';
export const myProfileSchema = Yup.object().shape({
  first_name: ALPHABETIC_REQUIRED_FIELD,
  last_name: ALPHABETIC_REQUIRED_FIELD,
  phone: PHONE_FIELD,
  profile: PHOTO_FIELD,
  role: Yup.string(),
  company_name: Yup.string()
    .ensure()
    .when('role', {
      is: (value) => value === ROLES.COMPANY,
      then: () => REQUIRED_FIELD,
      otherwise: () => Yup.mixed().notRequired(),
    }),
});
export const changePasswordSchema = Yup.object().shape({
  current_password: REQUIRED_FIELD,
  new_password: PASSWORD,
  confirm_password: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'Both passwords must match!',
  ),
});
