import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Loader from '../Loader';
import { IconArrowNarrowRight } from '@tabler/icons-react';

const CustomModal = ({
  size = '',
  show = false,
  isLoading = false,
  onHide = () => {},
  modalHeading = '',
  hadBodyPadding = false,
  children,
  hasActions = true,
  hasSave = true,
  hasClose = true,
  hasBack = false,
  saveBtnText = 'Save',
  closeBtnText = 'Cancel',
  backBtnText = 'Back',
  fullscreen = false,
  scrollable = false,
  onSaveCallback = () => {},
  onBackCallback = () => {},
  centered = false,
  customFooter = '',
  scrollToEnd = false,
}) => {
  const [modalShow, setModalShow] = useState(show);
  const modalContentRef = useRef(null);
  useEffect(() => {
    if (modalContentRef.current && scrollToEnd) {
      modalContentRef.current.scrollTop = modalContentRef.current.scrollHeight;
    }
  }, [children, scrollToEnd]);
  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      onExited={onHide}
      size={size}
      fullscreen={fullscreen}
      scrollable={scrollable}
      centered={centered}
    >
      {isLoading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title as="div" bsPrefix="modal-title text-primary fw-bold">
          {modalHeading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        ref={modalContentRef}
        className={hadBodyPadding ? 'p-0' : ''}
        style={{ overflowX: `hidden-` }}
      >
        {children}
        {customFooter}
        {hasActions && (
          <div className={hadBodyPadding ? 'modal-body pt-0' : ''}>
            <div>
              {hasBack && (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={onBackCallback}
                >
                  {backBtnText}
                </Button>
              )}
            </div>
            <div className="text-end">
              {hasClose && (
                <Button variant="" onClick={handleClose}>
                  {closeBtnText}
                </Button>
              )}
              {hasSave && (
                <Button type="submit" className="ms-2" onClick={onSaveCallback}>
                  {saveBtnText}{' '}
                  <IconArrowNarrowRight className="icon ms-1 me-0 " />
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
