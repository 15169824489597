import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLoginModal: {
    login: true,
    signup: false,
  },
};

const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    showLoginModal: (state, payload) => {
      state.showLoginModal.login = payload.payload.login;
      state.showLoginModal.signup = payload.payload.signup;
    },
    hideLoginModal: (state) => {
      state.showLoginModal = {
        login: false,
        signup: false,
      };
    },
  },
});
export const { showLoginModal, hideLoginModal } = loginModalSlice.actions;
export default loginModalSlice.reducer;
