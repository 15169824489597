import React, { Fragment, Suspense, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import useLogin from '../../hooks/useLogin';
import AnimatedComponent from '../Animation/AnimatedComponent';
import Header from '../Interface/Header';
import Footer from '../Interface/Footer';
import Loader from '../Loader';
import { PageWrapper } from '../Interface/PageWrapper';
import { useSelector } from 'react-redux';
import { ROLES } from '../../common/constants';

const ProtectedNode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const getRoute = useLogin(authSelector.auth);
  useEffect(() => {
    const protectedPath = [
      '/add-property',
      '/my-listings',
      '/add-units',
      '/offer-review',
      '/plan-details',
    ];
    if (protectedPath.includes(location.pathname)) {
      if (
        authSelector.role === ROLES.COMPANY ||
        authSelector.role === ROLES.OWNER
      ) {
        if (
          !authSelector?.is_active_plan &&
          authSelector?.device !== 'mobile'
        ) {
          navigate('/plan-expired');
        }
      }
    }
  }, [location?.pathname]);
  return (
    <>
      <Header />
      <AnimatedComponent
        as={PageWrapper}
        className="animate-fade h-100"
        animationClass="in"
      >
        <Suspense fallback={<Loader top="64px" height="calc(100% - 64px)" />}>
          {useRoutes(getRoute)}
        </Suspense>
        <Footer />
      </AnimatedComponent>
    </>
  );
};

export default ProtectedNode;
